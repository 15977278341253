const dayjs = require('dayjs');
const desiredParams = {
  UTM_source: null,
  UTM_medium: null,
  UTM_campaign: null,
  UTM_term: null,
  UTM_content: null,
};

const hasUTMParams = () => {
  const search = new URLSearchParams(window?.location.search);
  return Object.keys(desiredParams)
    .map((e) => e.toLowerCase())
    .some((e) => search.has(e));
};

module.exports.saveUTMParams = () => {
  const search = new URLSearchParams(window?.location.search);

  const hasUTMParamsValue = hasUTMParams();

  let hasSetValue = false;

  const expirationDate = dayjs(localStorage.getItem('UTM_expiration')).diff(dayjs(), 'day');

  if (expirationDate < 0 || hasUTMParamsValue) {
    localStorage.removeItem('UTM_expiration');
    Object.keys(desiredParams).forEach((param) => {
      localStorage.removeItem(param);
    });
  }

  Object.keys(desiredParams).forEach((param) => {
    const oneParam = search.get(param.toLowerCase());
    if (oneParam && oneParam.length > 0) {
      console.log('UTMParams: ' + param.toLowerCase() + ' = ' + oneParam);
      localStorage.setItem(param, oneParam);
      hasSetValue = true;
    }
  });

  if (hasSetValue) {
    localStorage.setItem('UTM_expiration', dayjs().add(1, 'day').toString());
  }
};

module.exports.clearUTMParams = () => {
  Object.keys(desiredParams).forEach((param) => {
    localStorage.removeItem(param);
  });
};

module.exports.getUTMParams = () => {
  const finalParams = {};
  Object.keys(desiredParams).forEach((param) => {
    const paramValue = localStorage.getItem(param);
    if (paramValue !== null) {
      finalParams[param] = paramValue;
    }
  });

  return finalParams;
};
